import { useState, useEffect } from 'react';
import queryString from "query-string";
import config from './config';
import List from './List/List';
import './App.css';
/** Logo資源 */
import logo_04_nagata from './Logo/04_nagata.png';

const logoMap = {
	"本庄軒":  logo_04_nagata,
}

function App() {
	const [count, setCount] = useState(config.refreshDuration);
	const [company, setCompany] = useState('');
	const [listData, setListData] = useState([]);
	const updateCount = () => {
		if (count > 0) {
			setCount(count - 1);
		} else {
			setCount(config.refreshDuration);
			getAPI();
		}
	};
	const getAPI = () => {
		const query = queryString.parse(window.location.search);
		const queryStr = `?hashID=${query.hashID}`;
		const fetchParams = { method:'GET', headers: { "Accept": "application/json" } };
		fetch(config.apiAddress + queryStr, fetchParams)
			.then(response => response.json())
			.then(response => {
				const services = response.services;
				if (Array.isArray(services)) {
					setListData(services);
				}
				setCompany(response.com);
			})
			.catch(err => console.log('錯誤:', err));
	}

	/** 初次生成時呼叫一次API */
	useEffect(() => getAPI(), []);
	/** 每秒更新一次畫面 */
	useEffect(() => {
		const timer = setInterval(() => {
			updateCount();
		}, 1000);
		return () => {
			clearInterval(timer);
		};
	});

	return (
		<div className="App">
			<div className="App-header">
				<div>
					<img src={logoMap[company]} className="App-logo" />
				</div>
				<List users={listData}/>
			</div>
			<div className="App-footer">
				<p> 頁面將於{count}秒後自動更新一次 </p>
				<p> {new Date().toString()} </p>
			</div>
		</div>
	);
}

export default App;
