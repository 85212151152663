import React from 'react';
import './List.css';

const List = (props) => {
    return (
        <div className='list'>
            <div className='row-header row-border'>
                <div className='item'>線上看號</div>
            </div>
            <div className='row-group row-border'>
                <div className='item'></div>
                <div className='item title'>目前叫號</div>
            </div>
            {props.users.map((user, index) => {
                    return (
                        <div className='row-group' key={index}>
                            <div className='item kindof'>{user.kindof}</div>
                            <div className='item number'>{user.number}</div>
                        </div>
                    )
                })}
        </div>
    )
}

export default List;
